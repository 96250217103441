import { Typography } from '@material-ui/core'
import React from 'react'
import { Box } from '@material-ui/core'

const Logo = (props) => {
  return (
    <Box>
      <Typography variant="h2" style={{ color: '#5FD58C' }}>
        NFT
      </Typography>
    </Box>
  )
  //<img src="/images/SSS_V3.png" alt="Logo" {...props} />
}

export default Logo
